<script>
import {faChevronLeft, faChevronRight} from '@fortawesome/pro-light-svg-icons';

import {Vector} from '@teemill/common/classes';

import {clamp} from 'lodash';

import {h, withDirectives, resolveComponent, resolveDirective} from 'vue';

export default {
  name: 'ProductImageCarousel',

  props: {
    /**
     * The number of slides visible at once.
     * This can either be a number (E.g. 3) for non-responsive or an array of breakpoints and slides per page.
     * E.g. [[0, 1], [576, 2], [768, 3]]
     */
    slidesPerPage: {
      type: [Number, Array],
      default: 1,
    },

    /**
     * Should there be a space between each slide.
     */
    slideSpacing: Boolean,

    /**
     * The index of the slide that carousel should start on.
     */
    initialSlide: {
      type: Number,
      default: 1,
    },

    /**
     * If true, the buttons will appear inside the carousel
     */
    insetNavigation: Boolean,

    /**
     * When true, the navigation buttons will be hidden.
     */
    hideNavigation: Boolean,
  },

  data() {
    return {
      faChevronLeft,
      faChevronRight,
      activeSlide: 0,
      pixelOffset: 0,
      mouseStartPoint: false,
      slideCount: 0,
      mouseDirection: false,
      slidesPerPageCount: 1,
    };
  },

  computed: {
    children() {
      const children = [];
      this.$slots.default()[0].children.forEach(child => {
        if (typeof child.type === 'symbol' && Array.isArray(child.children)) {
          children.push(
            ...child.children.filter(c => typeof c.type !== 'symbol')
          );
        } else if (typeof child.type !== 'symbol') {
          children.push(child);
        }
      });

      return children;
    },
  },

  mounted() {
    document.addEventListener('touchend', this.onMouseUp);
    document.addEventListener('mouseup', this.onMouseUp);

    this.onResize();
    window.addEventListener('resize', this.onResize);

    this.activeSlide = this.initialSlide - 1;
  },

  updated() {
    this.updateSlideCount();
  },

  unmounted() {
    window.removeEventListener('touchend', this.onMouseUp);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    pageCount() {
      if (this.slidesPerPageCount === 1) {
        return this.children.length;
      }

      return Math.ceil(this.children.length / this.slidesPerPageCount);
    },

    goToPage(index) {
      this.activeSlide = index;
    },

    onResize() {
      if (this.$slots.default()) {
        let slideCount = 1;

        if (Array.isArray(this.slidesPerPage)) {
          const slidesArray = this.slidesPerPage;
          const windowWidth = window.innerWidth;

          for (let i = 0, l = slidesArray.length; i < l; ++i) {
            if (slidesArray[i][0] >= windowWidth) {
              this.slidesPerPageCount = slideCount;
              break;
            }

            slideCount = slidesArray[i][1];
          }
          this.slidesPerPageCount = slideCount;
        } else {
          this.slidesPerPageCount = this.slidesPerPage;
        }

        this.updateSlideCount();
      }
    },

    currentBannerIndex() {
      return this.activeSlide;
    },

    getElClasses() {
      return {
        'slide-spacing': this.slideSpacing,
      };
    },

    getInnerElStyle() {
      const pages = this.pageCount();
      const width = pages * 100;
      const offset = this.activeSlide * (100 / pages);

      return {
        width: `${width}%`,
        transform: `translateX(calc(-${offset}% + ${this.pixelOffset}px))`,
        transition: this.mouseStartPoint ? 'none' : 'transform 0.4s ease',
        willChange: 'transform',
      };
    },

    changeSlide(delta) {
      this.activeSlide += delta;
      this.activeSlide = clamp(
        this.activeSlide,
        0,
        Math.floor((this.slideCount - 1) / this.slidesPerPageCount)
      );
    },

    onMouseDown(e) {
      this.mouseStartPoint = new Vector(e.clientX, e.clientY);
    },

    onMouseUp() {
      this.mouseStartPoint = false;
      if (this.pixelOffset > 50) {
        this.changeSlide(-1);
      } else if (this.pixelOffset < -50) {
        this.changeSlide(1);
      }
      this.pixelOffset = 0;
      this.mouseDirection = false;
    },

    onMouseMove(e) {
      if (this.mouseStartPoint) {
        const newMousePoint = new Vector(e.clientX, e.clientY);
        if (!this.mouseDirection) {
          const angle = Math.abs(
            this.mouseStartPoint.angleTo(newMousePoint) *
              newMousePoint.rad2degree
          );
          if (angle > 45 && angle < 135) {
            this.mouseDirection = 'vertical';
          } else {
            this.mouseDirection = 'horizontal';
          }
        }
        if (this.mouseDirection === 'horizontal') {
          e.preventDefault();
          this.pixelOffset = newMousePoint.x - this.mouseStartPoint.x;
        }
      }
    },

    getArrowClasses(side) {
      const classes = {
        'inset-navigation': this.insetNavigation,
      };

      if (this.hideNavigation === true) {
        classes.off = true;
      }

      if (side === 'left') {
        if (this.currentBannerIndex() === 0) {
          classes.off = true;
        }
      } else if (side === 'right') {
        if (this.currentBannerIndex() === this.pageCount() - 1) {
          classes.off = true;
        }
      }

      return classes;
    },

    updateSlideCount() {
      let slideCount = 0;

      if (this.$slots.default()) {
        slideCount = this.children.length;
      }

      this.slideCount = slideCount;
    },
  },

  render() {
    if (this.$slots.default()) {
      return withDirectives(
        h(
          'div',
          {
            class: {
              'tml-carousel': true,
              ...this.getElClasses(),
            },
            onMousedown: this.onMouseDown,
            onMousemove: this.onMouseMove,
          },
          [
            h(
              'a',
              {
                class: {
                  'tml-carousel-page-button': true,
                  'tml-carousel-page-button-left': true,
                  ...this.getArrowClasses('left'),
                },
                href: '#',
                'aria-label': 'See previous image',

                onClick: e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.changeSlide(-1);
                },
                onMousedown: e => {
                  e.stopPropagation();
                },
              },
              [
                h(resolveComponent('font-awesome-icon'), {
                  icon: this.faChevronLeft,
                }),
              ]
            ),

            h('div', {class: ['tml-carousel-mask']}, [
              h(
                'div',
                {
                  class: ['tml-carousel-inner'],
                  style: this.getInnerElStyle(),
                },
                this.children.map(slide =>
                  h(
                    'div',
                    {
                      class: ['slide'],
                      style: {
                        width: `${
                          100 / (this.pageCount() * this.slidesPerPageCount)
                        }%`,
                        willChange: 'opacity',
                        padding: this.slideSpacing ? '0 0.25em' : null,
                        float: 'left',
                      },
                    },
                    [slide]
                  )
                )
              ),
            ]),

            h(
              'a',
              {
                class: {
                  'tml-carousel-page-button': true,
                  'tml-carousel-page-button-right': true,
                  ...this.getArrowClasses('right'),
                },
                href: '#',
                'aria-label': 'See next image',
                onClick: e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.changeSlide(1);
                },
                onMousedown: e => {
                  e.stopPropagation();
                },
              },
              [
                h(resolveComponent('font-awesome-icon'), {
                  icon: this.faChevronRight,
                }),
              ]
            ),
          ]
        ),
        [[resolveDirective('tml-touch-mouse')]]
      );
    }

    return null;
  },
};
</script>

<style lang="scss">
.tml-carousel {
  width: 100%;
  position: relative;

  .tml-carousel-mask {
    overflow: hidden;
  }

  .tml-carousel-page-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: white;
    padding: 0.25em;
    width: 1.5em;
    height: 1.5em;
    font-size: 1em;
    text-align: center;
    color: $text-color !important;
    box-sizing: content-box;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:active,
    &:focus {
      color: $dark-text-color !important;
    }

    &.off {
      opacity: 0;
      pointer-events: none;
    }

    &.tml-carousel-page-button-left {
      left: 0;
      transform: translate(-50%, -50%) scale(1.25);
      box-shadow: $standard-shadow;
    }

    &.tml-carousel-page-button-right {
      right: 0;
      transform: translate(50%, -50%) scale(1.25);
      box-shadow: $standard-shadow;
    }

    @media (min-width: 768px) {
      &.inset-navigation {
        &.tml-carousel-page-button-left {
          left: 30px;
        }

        &.tml-carousel-page-button-right {
          right: 30px;
        }

        .VueCarousel-navigation-button {
          &.VueCarousel-navigation-prev {
            transform: translate(0.9em, -50%) scale(1);
          }

          &.VueCarousel-navigation-next {
            transform: translate(-0.9em, -50%) scale(1);
          }

          &.VueCarousel-navigation--disabled {
            &.VueCarousel-navigation-prev {
              transform: translate(0.9em, -50%) scale(0);
            }

            &.VueCarousel-navigation-next {
              transform: translate(-0.9em, -50%) scale(0);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .tml-carousel {
    .tml-carousel-page-button {
      &.tml-carousel-page-button-left {
        left: 0;
        border-radius: 0 100px 100px 0;
        padding: 0.5em 0.5em 0.5em 0;
        transform: translateY(-50%);
      }

      &.tml-carousel-page-button-right {
        right: 0;
        border-radius: 100px 0 0 100px;
        padding: 0.5em 0 0.5em 0.5em;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
